// src/components/SignInPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignInPage.css';

// Microsoft logo icon as inline SVG
const MicrosoftIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 23 23">
    <path fill="#f25022" d="M1 1h10v10H1z"/>
    <path fill="#00a4ef" d="M1 12h10v10H1z"/>
    <path fill="#7fba00" d="M12 1h10v10H12z"/>
    <path fill="#ffb900" d="M12 12h10v10H12z"/>
  </svg>
);


export default function SignInPage() {
  const [showBypass, setShowBypass] = useState(false);
  const [bypassEmail, setBypassEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const navigate = useNavigate();

  // On mount, check if the user is already logged in by calling /app/api/user.
  useEffect(() => {
    setCheckingAuth(true);
    fetch('/app/api/user', { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Not authenticated');
        }
        return response.json();
      })
      .then(userData => {
        // If we receive valid user data, store it and redirect.
        localStorage.setItem('user', JSON.stringify(userData));
        navigate('/dashboard');
      })
      .catch(err => {
        //console.log('User not logged in:', err.message);
        // No redirection; user will see the sign in page.
      })
      .finally(() => {
        setCheckingAuth(false);
      });
  }, [navigate]);

  // Redirects to the SAML endpoint for authentication.
  const handleSamlLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    window.location.href = `/user`;
  };

  const handleBypass = (e) => {
    e.preventDefault();
    
    // Check if email is valid
    if (!bypassEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(bypassEmail)) {
      setError('Please enter a valid email address.');
      return;
    }
    
    // Check if email is from Viterbo
    if (!bypassEmail.endsWith('@viterbo.edu')) {
      setError('Email must be a Viterbo University email ending in @viterbo.edu');
      return;
    }
    
    // Check if email is one of the allowed developer emails
    const allowedEmails = ['dacummings@viterbo.edu', 'pabriskey@viterbo.edu'];
    if (!allowedEmails.includes(bypassEmail.toLowerCase())) {
      setError('This email is not authorized for developer bypass.');
      return;
    }
    
    // Clear previous errors
    setError('');
    
    // Extract the name from email (for a more personalized experience)
    const namePart = bypassEmail.split('@')[0];
    const formattedName = namePart
      .split(/[._-]/)
      .map(part => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
    
    // Create mock user data
    const bypassUser = {
      firstName: formattedName,
      lastName: 'Developer',
      email: bypassEmail,
      displayName: `${formattedName} Developer`,
      roles: ['developer', 'authenticated'],
      developerAccess: true
    };
    
    //console.log('Developer bypass successful:', bypassUser);
    
    // Store in local storage and navigate to dashboard
    localStorage.setItem('user', JSON.stringify(bypassUser));
    navigate('/dashboard');
  };

  if (checkingAuth) {
    return (
      <div className="signin-container">
        <div className="signin-card" role="status" aria-label="Checking authentication status">
          <div className="loading-spinner"></div>
          <p>Checking authentication status...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="signin-container">
      <div className="signin-card" role="main" aria-label="Sign in">
        <header>
          <img src="/app/viterbo.png" alt="Viterbo University Logo" />
          <h1>Sign in to Your Account</h1>
        </header>
        
        {error && <div className="signin-error" role="alert">{error}</div>}
        
        {!showBypass ? (
          <>
            <button
              className="btn-sso"
              onClick={handleSamlLogin}
              disabled={loading}
              aria-label="Sign in with Microsoft"
            >
              {loading ? (
                <>
                  <span className="loading-spinner"></span>
                  Signing in...
                </>
              ) : (
                <>
                  <MicrosoftIcon />
                  Sign in with Microsoft
                </>
              )}
            </button>
            
            
            {process.env.NODE_ENV === 'development' && (
              <span
                onClick={(e) => {
                  e.preventDefault();
                  setShowBypass(true);
                }}
                className="bypass-link"
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setShowBypass(true);
                  }
                }}
              >
                Developer bypass (temporary)
              </span>
            )}
          </>
        ) : (
          <>
            <input
              type="email"
              placeholder="your-email@viterbo.edu"
              value={bypassEmail}
              onChange={(e) => setBypassEmail(e.target.value)}
              className="bypass-input"
              aria-label="Bypass email input"
              autoFocus
            />
            
            <button 
              className="btn-bypass" 
              onClick={handleBypass}
              aria-label="Bypass sign in"
            >
              Bypass Sign In
            </button>
            
            <span
              onClick={(e) => {
                e.preventDefault();
                setShowBypass(false);
              }}
              className="bypass-link"
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setShowBypass(false);
                }
              }}
            >
              Back to Microsoft Sign In
            </span>
          </>
        )}
      </div>
    </div>
  );
}